import instance from '../axios';

export const navigationApi = {
  getAllNavigations: async (pageSize, currentPage, searchValue) =>
    instance.get('/v1/navigation/get-all-admin', {
      params: { pageSize, currentPage, searchValue },
    }),
  getNavigationsByUuid: async (uuid) => instance.get(`/v1/navigation/${uuid}`),
  createNavigation: async (data) => instance.post('/v1/navigation/create', data),
  updateNavigation: async (uuid, data) => instance.put(`/v1/navigation/update/${uuid}`, data),
  deleteNavigation: async (uuid) => instance.delete(`/v1/navigation/delete/${uuid}`),
};

import { ColumnDef } from '@tanstack/react-table';

export type ColumnType = {
  id: string;
  visitDate: string;
  userUuid: string;
  clientCountry: string;
  clientRegion: boolean;
  clientCity: string;
  clientTimezone: string;
};

export const useVisitsTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'ip', header: 'IP' },
  { accessorKey: 'visitDate', header: 'Date of visit' },
  { accessorKey: 'userUuid', header: 'user uuid' },
  { accessorKey: 'clientCountry', header: 'Client country' },
  { accessorKey: 'clientRegion', header: 'Client region' },
  { accessorKey: 'clientCity', header: 'Client city' },
  { accessorKey: 'clientTimezone', header: 'Client timezone' },
];

import React, { useState } from 'react';

import moment from 'moment';
import { Page } from '../index';
import { usePurchaseTableColumns } from './usePurchaseTableColumns';
import Loading from '../../components/Loading/Loading';
import { useGetPurchases } from '../../hooks/ReactQuery/Purchases/useGetPurchases';
import useQueryParams from '../../helpers/useQueryParams';

const Purchase = () => {
  const query = useQueryParams();
  const currentPageFromUrl = query.get('currentPage');

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromUrl ? +currentPageFromUrl : 1
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const { data, isFetching, isPending } = useGetPurchases(pageSize, currentPage, searchValue);

  if (isFetching && isPending) return <Loading />;

  const remainderDays = (endDate: string) => {
    const remainder = (
      (new Date(endDate).getTime() - new Date().getTime()) /
      (24 * 60 * 60 * 1000)
    ).toFixed(0);
    if (+remainder > 0) {
      return remainder;
    }
    return 0;
  };

  const formattedData: any = data?.purchases?.map((elem) => ({
    id: elem?.uuid,
    startDate: moment(elem?.startDate).format('DD/MM/YYYY'),
    endDate: moment(elem?.endDate).format('DD/MM/YYYY'),
    remainder: `${remainderDays(elem.endDate)} days`,
    duration: elem?.price?.titleEn,
    reasonRejection: elem?.reasonRejection,
    isActive: elem?.isActive,
    user: `${elem?.user?.firstName} ${elem?.user?.lastName}`,
    priceUuid: elem?.priceUuid,
    price: elem?.price?.price,
    orderId: elem?.orderId,
    isStatusChecked: elem?.isStatusChecked,
  }));

  return (
    <Page
      title="Purchases"
      description="Purchases info"
      columns={usePurchaseTableColumns}
      formattedData={formattedData}
      searchKey="title"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      dataTotalCount={data?.totalCount || 0}
      pageSize={pageSize}
      setSearchValue={setSearchValue}
    />
  );
};

export default Purchase;

import DOMPurify from 'dompurify';

const cleanHtmlContent = (htmlContent) => {
  const cleanDomElement = DOMPurify.sanitize(htmlContent, {
    USE_PROFILES: { html: true },
  });

  return cleanDomElement;
};

export default cleanHtmlContent;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { pageContentEn } from '../../../constants/pageContent';
import shownErrorMessage from '../../../helpers/shownErrorMessage';
import { orderApi } from '../../../api/services/orderApi';

export const useDeleteOrder = () => {
  const queryClient = useQueryClient();

  // eslint-disable-next-line consistent-return
  const mutationFunction = async (deletedOrderUuid: string) => {
    try {
      const response = await orderApi.deleteOrder(deletedOrderUuid);
      toast.success(response?.data?.message || pageContentEn.ORDER_DELETED_SUCCESSFULLY);
      return response;
    } catch (error) {
      const errorMessage = shownErrorMessage(error);
      toast.error(errorMessage);
    }
  };

  const { mutateAsync: deleteOrderMutate } = useMutation({
    mutationFn: mutationFunction,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orders'] });
    },
  });

  return { deleteOrderMutate };
};

import React, { useEffect, useState } from 'react';

import { Modal } from '../Modal/Modal';
import { Button } from '../../@/components/ui/button';
import { DatePickerWithRange } from '../../@/components/ui/DatePickerWithRange';

type DateRange = {
  from: Date | undefined;
  to?: Date | undefined;
};

interface AlertModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
  setData: (data: DateRange | undefined) => void;
}

export const DeleteVisitsModal: React.FC<AlertModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  loading,
  setData,
}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <Modal
      title="Are you sure?"
      description="This action cannot be undone"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="pt-6 space-x-2 flex items-center justify-end w-full">
        <DatePickerWithRange setData={setData} />
        <Button disabled={loading} variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={loading} variant="destructive" onClick={onConfirm}>
          Delete
        </Button>
      </div>
    </Modal>
  );
};

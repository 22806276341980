import * as yup from 'yup';

export const paymentRegionValidation = {
  nameEn: yup.string().required('Required'),
  nameRu: yup.string().required('Required'),
  isShown: yup.boolean().required('Required'),
  abbreviation: yup.string().required('Required'),
  order: yup.number().min(0).required('Required'),
};

export const paymentRegionUpdateValidation = {
  nameEn: yup.string().required('Required'),
  nameRu: yup.string().required('Required'),
  isShown: yup.boolean().required('Required'),
  abbreviation: yup.string().required('Required'),
  order: yup.number().min(0).required('Required'),
};

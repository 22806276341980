import instance from '../axios';

export const swiperSliderApi = {
  getAllSwiperSliders: async (pageSize, currentPage, searchValue) =>
    instance.get('/v1/swiper-slider/get-all-admin', {
      params: { pageSize, currentPage, searchValue },
    }),
  getSwiperSliderByUuid: async (uuid) => instance.get(`/v1/swiper-slider/${uuid}`),
  createSwiperSlider: async (data) => instance.post('/v1/swiper-slider/create', data),
  updateSwiperSlider: async (uuid, data) => instance.put(`/v1/swiper-slider/update/${uuid}`, data),
  deleteSwiperSlider: async (uuid) => instance.delete(`/v1/swiper-slider/delete/${uuid}`),
};

import instance from '../axios';

export const paymentRegionApi = {
  getAllPaymentRegions: async (pageSize, currentPage, searchValue) =>
    instance.get('/v1/payment-region/get-all-admin', {
      params: { pageSize, currentPage, searchValue },
    }),
  getPaymentRegionByUuid: async (uuid) => instance.get(`/v1/payment-region/get-by-uuid/${uuid}`),
  createPaymentRegion: async (data) => instance.post('/v1/payment-region/create', data),
  updatePaymentRegion: async (uuid, data) =>
    instance.put(`/v1/payment-region/update/${uuid}`, data),
  deletePaymentRegion: async (uuid) => instance.delete(`/v1/payment-region/delete/${uuid}`),
};

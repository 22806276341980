import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  storage: string;
};

export const useCourseStorageTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'storage', header: 'storage' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'courseStorage'} />,
  },
];

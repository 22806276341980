import instance from '../axios';

export const bookCategoriesApi = {
  getAllBookCategories: async (pageSize, currentPage, searchValue) =>
    instance.get('/v1/category-book/get-all-admin', {
      params: { pageSize, currentPage, searchValue },
    }),
  getBookCategoryByUuid: async (uuid) => instance.get(`/v1/category-book/${uuid}`),
  createBookCategory: async (data) => instance.post('/v1/category-book/create', data),
  updateBookCategory: async (uuid, data) => instance.put(`/v1/category-book/update/${uuid}`, data),
  deleteBookCategory: async (uuid) => instance.delete(`/v1/category-book/delete/${uuid}`),
};

import React, { useState } from 'react';

import useQueryParams from '../../helpers/useQueryParams';
import { Page } from '../index';
import { usePrivacyPolicyTableColumns, ColumnType } from './usePrivacyPolicyTableColumns';
import Loading from '../../components/Loading/Loading';
import { useGetPrivacyPolicy } from '../../hooks/ReactQuery/PrivacyPolicy/useGetPrivacyPolicy';

const PrivacyPolicy = () => {
  const query = useQueryParams();
  const currentPageFromUrl = query.get('currentPage');

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromUrl ? +currentPageFromUrl : 1
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const { data, isFetching, isPending } = useGetPrivacyPolicy(pageSize, currentPage, searchValue);

  if (isFetching && isPending) return <Loading />;

  const formattedData: ColumnType[] | undefined = data?.privacyPolicy?.map((elem: ColumnType) => ({
    id: elem?.uuid,
    privacyPolicyEn: elem?.privacyPolicyEn,
    privacyPolicyRu: elem?.privacyPolicyRu,
  }));

  return (
    <Page
      title="Privacy policy conditions"
      description="Privacy policy info"
      columns={usePrivacyPolicyTableColumns}
      formattedData={formattedData}
      searchKey="privacyPolicyEn"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      dataTotalCount={data?.totalCount || 0}
      pageSize={pageSize}
      setSearchValue={setSearchValue}
    />
  );
};

export default PrivacyPolicy;

import instance, { formDataInstance } from '../axios';

export const privacyPolicyApi = {
  getPrivacyPolicy: async () => instance.get('/v1/privacy-policy/get-admin'),
  getPrivacyPolicyByUuid: async (uuid) => instance.get(`/v1/privacy-policy/get-by-uuid/${uuid}`),
  createPrivacyPolicy: async (data) => instance.post('/v1/privacy-policy/create', data),
  updatePrivacyPolicy: async (uuid, data) =>
    formDataInstance.put(`/v1/privacy-policy/update/${uuid}`, data),
  deletePrivacyPolicy: async (uuid) => instance.delete(`/v1/privacy-policy/delete/${uuid}`),
};

import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '../../../redux/app/hooks';
import shownErrorMessage from '../../../helpers/shownErrorMessage';
import { setError } from '../../../redux/features/errorSlice';
import { booksApi } from '../../../api/services/booksApi';

export const useGetBooks = (
  pageSize,
  currentPage,
  searchValue,
  isFree,
  isShown,
  language,
  bookSubcategory,
  source
) => {
  const dispatch = useAppDispatch();

  // eslint-disable-next-line consistent-return
  const queryFunction = async () => {
    try {
      const response = await booksApi.getAllBooks(
        pageSize,
        currentPage,
        searchValue,
        isFree,
        isShown,
        language,
        bookSubcategory,
        source
      );
      return response.data;
    } catch (error) {
      const errorMessage = shownErrorMessage(error);
      dispatch(setError({ type: errorMessage, error }));
    }
  };

  return useQuery({
    queryKey: [
      'books',
      pageSize,
      currentPage,
      searchValue,
      isShown,
      isFree,
      language,
      bookSubcategory,
      source,
    ],
    queryFn: () => queryFunction(),
  });
};

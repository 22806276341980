import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { FormCreator } from '../../components';
import { privacyPolicyValidation, privacyPolicyUpdateValidation } from './privacyPolicyValidation';
import { usePrivacyPolicyCreatorFields } from './usePrivacyPolicyCreatorFields';
import { privacyPolicyApi } from '../../api/services/privacyPolicyApi';

const PrivacyPolicyNew: React.FC = () => {
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { privacyPolicy },
        } = await privacyPolicyApi.getPrivacyPolicyByUuid(uuid);

        setData(privacyPolicy);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Privacy Policy"
        description="Create a privacy policy"
        validation={data ? privacyPolicyUpdateValidation : privacyPolicyValidation}
        fields={usePrivacyPolicyCreatorFields()}
        submitFunction={
          data ? privacyPolicyApi.updatePrivacyPolicy : privacyPolicyApi.createPrivacyPolicy
        }
        initialData={data ? { ...data } : null}
      />
    )
  );
};

export default PrivacyPolicyNew;

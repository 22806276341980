import instance, { formDataInstance } from '../axios';

export const bookSubcategoriesApi = {
  getAllBookSubcategories: async (pageSize, currentPage, searchValue) =>
    instance.get('/v1/sub-category-book/get-all-admin', {
      params: { pageSize, currentPage, searchValue },
    }),
  getBookSubcategoryByUuid: async (uuid) => instance.get(`/v1/sub-category-book/${uuid}`),
  createBookSubcategory: async (data) =>
    formDataInstance.post('/v1/sub-category-book/create', data),
  updateBookSubcategory: async (uuid, data) =>
    formDataInstance.put(`/v1/sub-category-book/update/${uuid}`, data),
  deleteBookSubcategory: async (uuid) => instance.delete(`/v1/sub-category-book/delete/${uuid}`),
};

import instance from '../axios';

export const positionStatusApi = {
  getAllPositionStatus: async (pageSize, currentPage, searchValue) =>
    instance.get('/v1/position-status/get-all-admin', {
      params: { pageSize, currentPage, searchValue },
    }),
  getPositionStatusByUuid: async (uuid) => instance.get(`/v1/position-status/${uuid}`),
  createPositionStatus: async (data) => instance.post('/v1/position-status/create', data),
  updatePositionStatus: async (uuid, data) =>
    instance.put(`/v1/position-status/update/${uuid}`, data),
  deletePositionStatus: async (uuid) => instance.delete(`/v1/position-status/delete/${uuid}`),
};

import React, { useState } from 'react';

import { Page } from '../index';
import { usePaymentRegionTableColumns, ColumnType } from './usePaymentRegionTableColumns';
import Loading from '../../components/Loading/Loading';
import useQueryParams from '../../helpers/useQueryParams';
import { useGetPaymentRegion } from '../../hooks/ReactQuery/PaymentRegion/useGetPaymentRegion';

const PaymentRegion = () => {
  const query = useQueryParams();
  const currentPageFromUrl = query.get('currentPage');

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromUrl ? +currentPageFromUrl : 1
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const { data, isFetching, isPending } = useGetPaymentRegion(pageSize, currentPage, searchValue);

  if (isFetching && isPending) return <Loading />;

  const formattedData: ColumnType[] = data?.paymentRegions?.map((elem: any) => ({
    id: elem?.uuid,
    nameEn: elem?.nameEn,
    nameRu: elem?.nameRu,
    abbreviation: elem?.abbreviation,
    isShown: elem?.isShown,
    order: elem?.order,
  }));

  return (
    <Page
      title="Payment Region"
      description="Payment region info"
      columns={usePaymentRegionTableColumns}
      formattedData={formattedData}
      searchKey="nameEn"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      dataTotalCount={data?.totalCount || 0}
      pageSize={pageSize}
      setSearchValue={setSearchValue}
    />
  );
};

export default PaymentRegion;

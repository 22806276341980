import * as yup from 'yup';

export const privacyPolicyValidation = {
  privacyPolicyEn: yup.string().required('Required'),
  privacyPolicyRu: yup.string().required('Required'),
};

export const privacyPolicyUpdateValidation = {
  privacyPolicyEn: yup.string().required('Required'),
  privacyPolicyRu: yup.string().required('Required'),
};

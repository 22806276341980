import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { FormCreator } from '../../components';
import {
  termsConditionsValidation,
  termsConditionsUpdateValidation,
} from './termsConditionsValidation';
import { useTermsConditionsCreatorFields } from './useTermsConditionsCreatorFields';
import { termsConditionsApi } from '../../api/services/termsConditionsApi';

const TermsConditionsNew: React.FC = () => {
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { termsConditions },
        } = await termsConditionsApi.getTermsConditionsByUuid(uuid);

        setData(termsConditions);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Terms and Conditions"
        description="Create a terms and Conditions"
        validation={data ? termsConditionsUpdateValidation : termsConditionsValidation}
        fields={useTermsConditionsCreatorFields()}
        submitFunction={
          data ? termsConditionsApi.updateTermsConditions : termsConditionsApi.createTermsConditions
        }
        initialData={data ? { ...data } : null}
      />
    )
  );
};

export default TermsConditionsNew;

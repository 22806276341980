import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { FormCreator } from '../../components';
import { aboutValidation, aboutUpdateValidation } from './aboutValidation';
import { useAboutCreatorFields } from './useAboutCreatorFields';
import { aboutApi } from '../../api/services/aboutApi';

const AboutNew: React.FC = () => {
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { about },
        } = await aboutApi.getAboutByUuid(uuid);

        setData(about);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="About"
        description="Create a about"
        validation={data ? aboutUpdateValidation : aboutValidation}
        fields={useAboutCreatorFields()}
        submitFunction={data ? aboutApi.updateAbout : aboutApi.createAbout}
        initialData={data ? { ...data } : null}
      />
    )
  );
};

export default AboutNew;

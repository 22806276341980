import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import searchReducer from '../features/searchSlice';
import menuReducer from '../features/menuSlice';
import settingsReducer from '../features/settingsSlice';
import userReducer from '../features/userSlice';
import languagesReducer from '../features/languageSlice';
import subcategoriesReducer from '../features/subcategorySlice';
import bookSubcategoriesReducer from '../features/bookSubcategorySlice';
import ordersReducer from '../features/orderSlice';
import darkModeReducer from '../features/darkMode';
import premiumReducer from '../features/premiumSlice';
import courseReducer from '../features/courseSlice';

import { apiSlice } from '../features/languages/languagesApiSlice';

export const store = configureStore({
  reducer: {
    search: searchReducer,
    menu: menuReducer,
    settings: settingsReducer,
    user: userReducer,
    languages: languagesReducer,
    subcategories: subcategoriesReducer,
    bookSubcategories: bookSubcategoriesReducer,
    orders: ordersReducer,
    isDarkMode: darkModeReducer,
    isPremium: premiumReducer,
    course: courseReducer,

    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

// create types for state and dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

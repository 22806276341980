import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { pageContentEn } from '../../../constants/pageContent';
import shownErrorMessage from '../../../helpers/shownErrorMessage';
import { userApi } from '../../../api/services/userApi';

type DateRange = {
  from: Date | undefined;
  to?: Date | undefined;
};

export const useDeleteVisits = () => {
  const queryClient = useQueryClient();

  // eslint-disable-next-line consistent-return
  const mutationFunction = async (data: DateRange) => {
    try {
      if (data.from && data.to) {
        const response = await userApi.deleteUsersVisits(data.from, data.to);
        toast.success(response?.data?.message || pageContentEn.VISITS_DELETED_SUCCESSFULLY);
        return response;
      }
      throw new Error('Invalid startDate or endDate');
    } catch (error) {
      const errorMessage = shownErrorMessage(error);
      toast.error(errorMessage);
    }
  };

  const { mutateAsync: deleteVisitsMutate } = useMutation({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mutationFn: mutationFunction,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['visits'] });
    },
  });

  return { deleteVisitsMutate };
};

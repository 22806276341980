import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useSearchParams } from 'react-router-dom';
import { Page } from '../index';
import { useBookTableColumns, ColumnType } from './useBookTableColumns';
import Loading from '../../components/Loading/Loading';
import useQueryParams from '../../helpers/useQueryParams';
import { useGetAllSources } from '../../hooks/ReactQuery/Sources/useGetAllSources';
import { useGetBookSubcategories } from '../../hooks/ReactQuery/BookSubcategories/useGetBookSubcategories';
import { useGetBooks } from '../../hooks/ReactQuery/Books/useGetBooks';

const isFreeOptions = [
  { value: '', label: 'All courses' },
  { value: 'true', label: 'Free' },
  { value: 'false', label: 'Paid' },
];

const isShownOptions = [
  { value: '', label: 'All courses' },
  { value: 'true', label: 'Shown' },
  { value: 'false', label: 'Not shown' },
];

const languageOptions = [
  { value: '', label: 'All languages' },
  { value: 'english', label: 'English' },
  { value: 'russian', label: 'Russian' },
];

const Books = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedParams = new URLSearchParams(searchParams);
  const updateSearchParams = (key: string, value: string) => {
    if (value) {
      updatedParams.set(key, value); // Set searchValue parameter
    } else {
      updatedParams.delete(key); // Remove parameter if input is empty
    }
    setSearchParams(updatedParams);
  };

  const query = useQueryParams();
  const currentPageFromUrl = query.get('currentPage');
  const searchValueFromUrl = query.get('searchValue');
  const isShownFromUrl = query.get('isShown');
  const isFreeFromUrl = query.get('isFree');
  const languageFromUrl = query.get('language');
  const sourceFromUrl = query.get('source');
  const subcategoryFromUrl = query.get('subcategory');

  const { data: subcategoriesData } = useGetBookSubcategories();
  const { data: sourcesData } = useGetAllSources();

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromUrl ? +currentPageFromUrl : 1
  );
  const [searchValue, setSearchValue] = useState<string>(searchValueFromUrl || '');
  const [isShown, setIsShown] = useState<string>(isShownFromUrl || '');
  const [isFree, setIsFree] = useState<string>(isFreeFromUrl || '');
  const [language, seLanguage] = useState<string>(languageFromUrl || '');

  const [sourceOptions, setSourceOptions] = useState<any[]>([]);
  const [source, setSource] = useState<any>(sourceFromUrl || '');

  const [bookSubcategoryOptions, setBookSubcategoryOptions] = useState<any[]>([]);
  const [bookSubcategory, setBookSubcategory] = useState<any>(subcategoryFromUrl || '');

  const isFreeHandler = (e: any) => {
    setIsFree(e.value);
  };

  const isShownHandler = (e: any) => {
    setIsShown(e.value);
  };

  const languageHandler = (e: any) => {
    seLanguage(e.value);
  };

  const bookSubcategoryHandler = (e: any) => {
    setBookSubcategory(e.value);
  };

  const sourceHandler = (e: any) => {
    setSource(e.value);
  };

  const { data, isLoading, error } = useGetBooks(
    pageSize,
    currentPage,
    searchValue,
    isFree,
    isShown,
    language,
    bookSubcategory,
    source
  );

  useEffect(() => {
    if (subcategoriesData) {
      const option = [{ value: '', label: 'All subcategory' }];
      subcategoriesData?.bookSubcategories?.forEach((bookSubcategoryItem: any) => {
        option.push({ value: bookSubcategoryItem.uuid, label: bookSubcategoryItem.nameEn });
      });
      setBookSubcategoryOptions(option);
    }
  }, [subcategoriesData, subcategoriesData?.subcategories]);

  useEffect(() => {
    if (sourcesData) {
      const option = [{ value: '', label: 'All source' }];
      sourcesData?.sources?.forEach((sourceItem: any) => {
        option.push({ value: sourceItem.uuid, label: sourceItem.nameEn });
      });
      setSourceOptions(option);
    }
  }, [sourcesData]);

  useEffect(() => {
    updateSearchParams('searchValue', searchValue);
  }, [searchValue]);

  useEffect(() => {
    updateSearchParams('currentPage', currentPage.toString());
  }, [currentPage]);

  useEffect(() => {
    updateSearchParams('isShown', isShown.toString());
  }, [isShown]);

  useEffect(() => {
    updateSearchParams('isFree', isFree.toString());
  }, [isFree]);

  useEffect(() => {
    updateSearchParams('language', language);
  }, [language]);

  useEffect(() => {
    updateSearchParams('source', source);
  }, [source]);

  useEffect(() => {
    updateSearchParams('subcategory', bookSubcategory);
  }, [bookSubcategory]);

  const formattedData: ColumnType[] = data?.books?.map((elem: any) => ({
    id: elem?.uuid,
    image: elem?.image,
    zipFile: elem?.zipFile,
    bookSubcategories: elem?.bookSubcategories,
    source: elem?.source,
    titleEn: elem?.titleEn,
    titleRu: elem?.titleRu,
    pages: elem?.pages,
    isShown: elem?.isShown,
    language: elem?.language?.nameEn,
    releaseDate: moment(elem?.releaseDate).format('DD-MM-yyyy'),
    updateDate: elem?.updateDate,
    isFree: elem?.isFree,
    chLink: elem?.chLink,
  }));

  if (isLoading) return <Loading />;

  if (error) {
    return <div>Error loading courses</div>; // Handle error state
  }

  return (
    <Page
      title="Books"
      description="Books info"
      columns={useBookTableColumns}
      formattedData={formattedData}
      searchKey="titleEn"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      dataTotalCount={data?.totalCount || 0}
      pageSize={pageSize}
      setSearchValue={setSearchValue}
      isShown={isShown}
      isShownHandler={isShownHandler}
      isShownOptions={isShownOptions}
      isFree={isFree}
      isFreeHandler={isFreeHandler}
      isFreeOptions={isFreeOptions}
      language={language}
      languageHandler={languageHandler}
      languageOptions={languageOptions}
      subcategoryOptions={bookSubcategoryOptions}
      subcategoryHandler={bookSubcategoryHandler}
      subcategory={bookSubcategory}
      sourceOptions={sourceOptions}
      sourceHandler={sourceHandler}
      source={source}
      searchValueFromUrl={searchValueFromUrl}
    />
  );
};

export default Books;

import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  id: string;
  aboutEn: string;
  aboutRu: string;
};

export const useAboutTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'aboutEn', header: 'About english' },
  { accessorKey: 'aboutRu', header: 'About russian' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'about'} />,
  },
];

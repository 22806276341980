import React, { useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuLabel,
} from '@radix-ui/react-dropdown-menu';
import { useNavigate } from 'react-router-dom';
import { RefreshCcw, Copy, Edit, MoreHorizontal, Trash } from 'lucide-react';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../redux/app/hooks';
import { Button } from '../../../@/components/ui/button';
import { DropdownMenuContent, DropdownMenuItem } from '../../../@/components/ui/dropdown-menu';
import { AlertModal } from '../../AlertModal/AlertModal';
import { useDeleteNavigation } from '../../../hooks/ReactQuery/Navigation/useDeleteNavigation';
import { useDeleteCategory } from '../../../hooks/ReactQuery/Categories/useDeleteCategory';
import { useDeleteSubcategory } from '../../../hooks/ReactQuery/Subcategories/useDeleteSubcategory';
import { useDeletePositionStatus } from '../../../hooks/ReactQuery/PositionStatus/useDeletePositionStatus';
import { useDeleteLanguage } from '../../../hooks/ReactQuery/Language/useDeleteLanguage';
import { useDeleteSource } from '../../../hooks/ReactQuery/Sources/useDeleteSource';
import { useDeleteCourse } from '../../../hooks/ReactQuery/Courses/useDeleteCourse';
import { useDeleteBookCategory } from '../../../hooks/ReactQuery/BookCategories/useDeleteBookCategory';
import { useDeleteBookSubcategory } from '../../../hooks/ReactQuery/BookSubcategories/useDeleteBookSubcategory';
import { useDeleteBook } from '../../../hooks/ReactQuery/Books/useDeleteBook';
import { useDeleteSwiperSlider } from '../../../hooks/ReactQuery/SwiperSlide/useDeleteSwiperSlider';
import { useDeletePrice } from '../../../hooks/ReactQuery/Price/useDeletePrice';
import { useDeleteUser } from '../../../hooks/ReactQuery/Users/useDeleteUser';
import { useDeleteOrder } from '../../../hooks/ReactQuery/Orders/useDeleteOrder';
import { useDeleteAbout } from '../../../hooks/ReactQuery/About/useDeleteAbout';
import { useDeleteUserLanguage } from '../../../hooks/ReactQuery/UserLanguage/useDeleteUserLanguage';
import { useDeletePaymentProvider } from '../../../hooks/ReactQuery/PaymentProvider/useDeletePaymentProvider';
import { useDeletePaymentSystem } from '../../../hooks/ReactQuery/PaymentSystem/useDeletePaymentSystem';
import { useUpdateCourseVideoList } from '../../../hooks/ReactQuery/Courses/useUpdateCourseVideoList';
import { useDeleteTermsConditions } from '../../../hooks/ReactQuery/TermsConditions/useDeleteTermsConditions';
import { useDeletePrivacyPolicy } from '../../../hooks/ReactQuery/PrivacyPolicy/useDeletePrivacyPolicy';
import { setCourseUpdateId, setCourseUpdateProcess } from '../../../redux/features/courseSlice';
import { useDeleteCourseStorage } from '../../../hooks/ReactQuery/CourseStorage/useDeleteCourseStorage';

interface CellActionProps {
  data: any;
  section?: string;
  deleteItem?: any;
  deleteAction?: any;
}

export const CellAction: React.FC<CellActionProps> = ({ data, deleteAction, section }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { deleteNavigationMutate } = useDeleteNavigation();
  const { deleteCategoryMutate } = useDeleteCategory();
  const { deleteSubcategoryMutate } = useDeleteSubcategory();
  const { deletePositionStatusMutate } = useDeletePositionStatus();
  const { deleteLanguageMutate } = useDeleteLanguage();
  const { deleteCourseStorageMutate } = useDeleteCourseStorage();
  const { deleteUserLanguageMutate } = useDeleteUserLanguage();
  const { deleteSourceMutate } = useDeleteSource();
  const { deleteCourseMutate } = useDeleteCourse();
  const { deleteBookCategoryMutate } = useDeleteBookCategory();
  const { deleteBookSubcategoryMutate } = useDeleteBookSubcategory();
  const { deleteBookMutate } = useDeleteBook();
  const { deleteSwiperSliderMutate } = useDeleteSwiperSlider();
  const { deletePriceMutate } = useDeletePrice();
  const { deleteUserMutate } = useDeleteUser();
  const { deleteOrderMutate } = useDeleteOrder();
  const { deleteAboutMutate } = useDeleteAbout();
  const { deleteTermsConditionsMutate } = useDeleteTermsConditions();
  const { deletePrivacyPolicyMutate } = useDeletePrivacyPolicy();
  const { deletePaymentProviderMutate } = useDeletePaymentProvider();
  const { deletePaymentSystemMutate } = useDeletePaymentSystem();
  const { updateCourseInformationMutate } = useUpdateCourseVideoList();

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const onCopy = (id: string) => {
    navigator.clipboard.writeText(id);
    toast.success('Billboard id copied to the clipboard');
  };

  const updateHandler = (id: string) => {
    navigate(`${id}`);
  };

  const deleteHandler = async () => {
    try {
      setLoading(true);
      if (deleteAction === 'navigation') {
        await deleteNavigationMutate(data.id);
      }
      if (deleteAction === 'category') {
        await deleteCategoryMutate(data.id);
      }
      if (deleteAction === 'subcategory') {
        await deleteSubcategoryMutate(data.id);
      }
      if (deleteAction === 'positionStatus') {
        await deletePositionStatusMutate(data.id);
      }
      if (deleteAction === 'language') {
        await deleteLanguageMutate(data.id);
      }
      if (deleteAction === 'userLanguage') {
        await deleteUserLanguageMutate(data.id);
      }
      if (deleteAction === 'source') {
        await deleteSourceMutate(data.id);
      }
      if (deleteAction === 'course') {
        await deleteCourseMutate(data.id);
      }
      if (deleteAction === 'bookCategory') {
        await deleteBookCategoryMutate(data.id);
      }
      if (deleteAction === 'bookSubcategory') {
        await deleteBookSubcategoryMutate(data.id);
      }
      if (deleteAction === 'book') {
        await deleteBookMutate(data.id);
      }
      if (deleteAction === 'swiperSlider') {
        await deleteSwiperSliderMutate(data.id);
      }
      if (deleteAction === 'price') {
        await deletePriceMutate(data.id);
      }
      if (deleteAction === 'paymentProvider') {
        await deletePaymentProviderMutate(data.id);
      }
      if (deleteAction === 'paymentSystem') {
        await deletePaymentSystemMutate(data.id);
      }
      if (deleteAction === 'user') {
        await deleteUserMutate(data.id);
      }
      if (deleteAction === 'order') {
        await deleteOrderMutate(data.id);
      }
      if (deleteAction === 'about') {
        await deleteAboutMutate(data.id);
      }
      if (deleteAction === 'termsConditions') {
        await deleteTermsConditionsMutate(data.id);
      }
      if (deleteAction === 'privacyPolicy') {
        await deletePrivacyPolicyMutate(data.id);
      }
      if (deleteAction === 'courseStorage') {
        await deleteCourseStorageMutate(data.id);
      }
    } catch (error) {
      toast.error('Something went wrong');
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const onUpdateVideoList = async (id: string) => {
    dispatch(setCourseUpdateProcess());
    dispatch(setCourseUpdateId(id));
    if (section === 'course') {
      try {
        await updateCourseInformationMutate(id);
      } catch (error) {
        toast.error('Something went wrong');
      } finally {
        dispatch(setCourseUpdateProcess());
        dispatch(setCourseUpdateId(null));
      }
    }
  };

  const copyUrl = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <>
      <AlertModal
        isOpen={open}
        onClose={() => setOpen(false)}
        loading={loading}
        onConfirm={deleteHandler}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">Open Menu</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel className="font-bold text-sm">Actions</DropdownMenuLabel>
          <DropdownMenuItem className="cursor-pointer" onClick={() => onCopy(data.id)}>
            <Copy className="mr-2 h-4 w-4" />
            Copy Id
          </DropdownMenuItem>
          {section === 'course' && (
            <DropdownMenuItem className="cursor-pointer" onClick={() => copyUrl(data.chLink)}>
              <Copy className="mr-2 h-4 w-4" />
              Copy CH URL
            </DropdownMenuItem>
          )}
          {section === 'course' && (
            <DropdownMenuItem className="cursor-pointer" onClick={() => copyUrl(data.originalLink)}>
              <Copy className="mr-2 h-4 w-4" />
              Copy Original URL
            </DropdownMenuItem>
          )}
          <DropdownMenuItem className="cursor-pointer" onClick={() => updateHandler(data.id)}>
            <Edit className="mr-2 h-4 w-4" />
            Update
          </DropdownMenuItem>
          <DropdownMenuItem className="cursor-pointer" onClick={() => setOpen(true)}>
            <Trash className="mr-2 h-4 w-4" />
            Delete
          </DropdownMenuItem>
          {section === 'course' && (
            <DropdownMenuItem className="cursor-pointer" onClick={() => onUpdateVideoList(data.id)}>
              <RefreshCcw className="mr-2 h-4 w-4" />
              Update Videos
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

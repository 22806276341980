import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  uuid: string;
  id: string;
  privacyPolicyEn: string;
  privacyPolicyRu: string;
};

export const usePrivacyPolicyTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'privacyPolicyEn', header: 'Privacy policy english' },
  { accessorKey: 'privacyPolicyRu', header: 'Privacy policy russian' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'privacyPolicy'} />,
  },
];

import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const defaultOptions = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Attendance chart',
    },
  },
};

const SparkLine = ({ LineChartData, options = defaultOptions, data = {} }) => {
  const entries = Object.entries(data);
  const sortedEntries = entries.sort((a, b) => {
    const [dayA, monthA, yearA] = a[0].split('/').map(Number);
    const [dayB, monthB, yearB] = b[0].split('/').map(Number);

    const dateA = new Date(yearA, monthA - 1, dayA);
    const dateB = new Date(yearB, monthB - 1, dayB);

    return dateA - dateB;
  });
  const sortedData = Object.fromEntries(sortedEntries);

  const days = Object.keys(sortedData);
  const daysCount = Object.values(sortedData);

  return (
    <Line options={options} data={LineChartData(days, daysCount)} width={'100%'} height={'300px'} />
  );
};

export default SparkLine;

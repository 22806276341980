import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '../../../redux/app/hooks';
import shownErrorMessage from '../../../helpers/shownErrorMessage';
import { navigationApi } from '../../../api/services/navigationApi';
import { setError } from '../../../redux/features/errorSlice';

export const useGetNavigations = (pageSize, currentPage, searchValue) => {
  const dispatch = useAppDispatch();

  // eslint-disable-next-line consistent-return
  const queryFunction = async () => {
    try {
      const response = await navigationApi.getAllNavigations(pageSize, currentPage, searchValue);
      return response.data;
    } catch (error) {
      const errorMessage = shownErrorMessage(error);
      dispatch(setError({ type: errorMessage, error }));
    }
  };

  return useQuery({
    queryKey: ['navigations', pageSize, currentPage, searchValue],
    queryFn: () => queryFunction(),
  });
};

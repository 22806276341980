import React, { useEffect, useState } from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';

import { MdOutlineSupervisorAccount } from 'react-icons/md';
import { TbPremiumRights } from 'react-icons/tb';

import moment from 'moment';
import { Button } from '../components';

import { useStateContext } from '../contexts/ContextProvider';
import SparkLine from '../components/Charts/SparkLine';
import { purchaseApi } from '../api/services/purchaseApi';
import { purchaseType, userType } from '../../types/types';
import { userApi } from '../api/services/userApi';
import { useGetUsersDaysVisits } from '../hooks/ReactQuery/Users/useGetUsersDaysVisits';
import { useGetUsersThirtyDaysRegistrations } from '../hooks/ReactQuery/Users/useGetUsersRegistrations';
import PieChart from '../components/Charts/PieChart';
import { useGetUsersPremiumNotPremiumData } from '../hooks/ReactQuery/Users/useGetUsersPremiumData';

const countTotalPurchase = (purchaseData: purchaseType[] = []) => {
  let totalPurchase = 0;
  if (purchaseData?.length > 0) {
    purchaseData.forEach((purchase) => {
      totalPurchase += purchase?.price?.price as number;
    });
  }

  return totalPurchase.toFixed(2);
};

const countUsers = (usersData: userType[] = []) => {
  let totalUsers;
  if (usersData?.length > 0) {
    totalUsers = usersData?.length;
  }

  return totalUsers;
};

const attendanceSparkLineOptions = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Attendance chart',
    },
  },
};

export const attendanceLineChartData = (dataLabels: string, dataDays: string) => ({
  labels: dataLabels,
  datasets: [
    {
      label: 'Daily visits',
      data: dataDays,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      yAxisID: 'y',
    },
  ],
});

const registrationSparkLineOptions = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Registration chart',
    },
  },
};

export const registrationLineChartData = (dataLabels: string, dataDays: string) => ({
  labels: dataLabels,
  datasets: [
    {
      label: 'Registrations',
      data: dataDays,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      yAxisID: 'y',
    },
  ],
});

const Dashboard = () => {
  const { currentColor } = useStateContext();
  const [purchases, setPurchases] = useState<purchaseType[] | null>(null);
  const [users, setUsers] = useState<userType[] | null>(null);

  const [premiumUsers, setPremiumUsers] = useState<any>(0);
  const [notPremiumUsers, setNotPremiumUsers] = useState<any>(0);

  const { data: usersDaysVisits, isFetching, isPending } = useGetUsersDaysVisits();
  const { data: registrationInformation } = useGetUsersThirtyDaysRegistrations();
  const { data: premiumInformation } = useGetUsersPremiumNotPremiumData();

  const visitsDateCounts: any = {};
  const registrationDateCounts: any = {};

  usersDaysVisits?.visitInformation?.forEach((entry) => {
    const date = entry.visitDate;
    if (visitsDateCounts[date]) {
      visitsDateCounts[date] += 1;
    } else {
      visitsDateCounts[date] = 1;
    }
  });

  registrationInformation?.registrationInformation?.forEach((entry) => {
    const date = moment(entry.createdAt).format('DD/MM/YYYY');
    if (registrationDateCounts[date]) {
      registrationDateCounts[date] += 1;
    } else {
      registrationDateCounts[date] = 1;
    }
  });

  useEffect(() => {
    if (premiumInformation?.premiumInformation?.premium) {
      setPremiumUsers(premiumInformation?.premiumInformation?.premium);
    }
    if (premiumInformation?.premiumInformation?.notPremium) {
      setNotPremiumUsers(premiumInformation?.premiumInformation?.notPremium);
    }
  }, [premiumInformation]);

  useEffect(() => {
    const getPurchasesData = async () => {
      const { data } = await purchaseApi.getAllPurchases(10000, 1, '');
      setPurchases(data?.purchases);
    };

    getPurchasesData().catch((error) => console.error('error', error));
  }, []);

  useEffect(() => {
    const getUsersData = async () => {
      const { data } = await userApi.getAllUsers();
      setUsers(data?.users);
    };

    getUsersData().catch((error) => console.error('error', error));
  }, []);

  return (
    <div>
      <div className="flex flex-wrap lg:flex-nowrap justify-start">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-start items-start flex-col">
            <p className="font-bold text-gray-400 mb-2">Earnings</p>
            <div className="flex items-center">
              <button
                type="button"
                style={{ backgroundColor: currentColor }}
                className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full mr-2 p-4"
                onClick={() => {
                  console.log('onClick');
                }}
              >
                <BsCurrencyDollar />
              </button>
              <p className="text-2xl">{countTotalPurchase(purchases as purchaseType[])}</p>
            </div>
          </div>
          <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Download"
              borderRadius="10px"
              size={undefined}
              onClick={() => {
                console.log('onClick');
              }}
            />
          </div>
        </div>
        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          {/* users */}
          <div
            key={'Customers'}
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="font-bold text-gray-400 mb-2">Registered users</p>
            <div className="flex items-center">
              <button
                type="button"
                style={{ color: '#03C9D7', backgroundColor: '#E5FAFB' }}
                className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full mr-2 p-4"
              >
                <MdOutlineSupervisorAccount />
              </button>
              <p className="mt-3">
                <span className="text-lg font-semibold">{countUsers(users as userType[])} </span>
              </p>
            </div>
          </div>

          <div
            key={'PremiumCustomers'}
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="font-bold text-gray-400 mb-2">Premium users</p>
            <div className="flex items-center">
              <button
                type="button"
                style={{ color: '#03C9D7', backgroundColor: '#E5FAFB' }}
                className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full mr-2 p-4"
              >
                <TbPremiumRights />
              </button>
              <p className="mt-3">
                <span className="text-lg font-semibold">
                  {premiumInformation?.premiumInformation?.premium}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-10 flex-wrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl w-full md:w780">
          <div className="flex justify-between">
            <p className="font-semibold text-xl text-gray-700 ml-2">Charts</p>
          </div>

          <div className="flex justify-between">
            <p className="font-semibold text-sm text-gray-700 ml-8 mt-2">Daily visits</p>
          </div>
          <div className="flex flex-wrap justify-start">
            <div className="border-r-1 border-color w-full">
              <div className="mt-5">
                <SparkLine
                  LineChartData={attendanceLineChartData}
                  options={attendanceSparkLineOptions}
                  data={visitsDateCounts}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between">
            <p className="font-semibold text-sm text-gray-700 ml-8 mt-2">Daily registration</p>
          </div>
          <div className="flex flex-wrap justify-start">
            <div className="border-r-1 border-color w-full">
              <div className="mt-5">
                <SparkLine
                  LineChartData={registrationLineChartData}
                  options={registrationSparkLineOptions}
                  data={registrationDateCounts}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between mt-5">
            <p className="font-semibold text-sm text-gray-700 ml-8 mt-2">Premium vs Not Premium</p>
          </div>
          <div className="flex flex-wrap justify-start w-[50%] h-auto">
            <div className="border-r-1 border-color w-full">
              <div className="mt-5">
                <PieChart
                  pieChartData={{
                    labels: ['Premium', 'Not Premium'],
                    datasets: [
                      {
                        data: [premiumUsers, notPremiumUsers],
                        backgroundColor: ['#00ff88', '#FF6384'],
                        hoverBackgroundColor: ['#00ff88', '#FF6384'],
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import instance from '../axios';

export const courseStorageApi = {
  getAllCourseStorages: async (pageSize, currentPage, searchValue) =>
    instance.get('/v1/course-storage/get-all', { params: { pageSize, currentPage, searchValue } }),
  getCourseStorageByUuid: async (uuid) => instance.get(`/v1/course-storage/${uuid}`),
  createCourseStorage: async (data) => instance.post('/v1/course-storage/create', data),
  updateCourseStorage: async (uuid, data) =>
    instance.put(`/v1/course-storage/update/${uuid}`, data),
  deleteCourseStorage: async (uuid) => instance.delete(`/v1/course-storage/delete/${uuid}`),
};

import * as yup from 'yup';

export const aboutValidation = {
  aboutEn: yup.string().required('Required'),
  aboutRu: yup.string().required('Required'),
};

export const aboutUpdateValidation = {
  aboutEn: yup.string().required('Required'),
  aboutRu: yup.string().required('Required'),
};

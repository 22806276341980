import instance from '../axios';

export const paymentProviderApi = {
  getAllPaymentProviders: async (pageSize, currentPage, searchValue) =>
    instance.get('/v1/payment-provider/get-all-admin', {
      params: { pageSize, currentPage, searchValue },
    }),
  getPaymentProviderByUuid: async (uuid) =>
    instance.get(`/v1/payment-provider/get-by-uuid/${uuid}`),
  createPaymentProvider: async (data) => instance.post('/v1/payment-provider/create', data),
  updatePaymentProvider: async (uuid, data) =>
    instance.put(`/v1/payment-provider/update/${uuid}`, data),
  deletePaymentProvider: async (uuid) => instance.delete(`/v1/payment-provider/delete/${uuid}`),
};

import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  uuid: string;
  id: string;
  termsConditionsEn: string;
  termsConditionsRu: string;
};

export const useTermsConditionsTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'termsConditionsEn', header: 'Terms conditions english' },
  { accessorKey: 'termsConditionsRu', header: 'Terms conditions russian' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'termsConditions'} />,
  },
];

import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  id: string;
  image: string;
  titleEn: string;
  titleRu: string;
  duration: string;
  numberOfLessons: string;
  languageUuid: string;
  releaseDate: string;
  createdAt: string;
  updateDate: string;
  isFree: string;
  chLink: string;
  originalLink: string;
  courseStorageUuid: string;
};

export const useCourseTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'image', header: 'image' },
  { accessorKey: 'zipFile', header: 'Zip file' },
  { accessorKey: 'titleEn', header: 'Title english' },
  { accessorKey: 'duration', header: 'Duration' },
  { accessorKey: 'numberOfLessons', header: 'Number of lessons' },
  { accessorKey: 'language', header: 'Language' },
  { accessorKey: 'isShown', header: 'Is shown' },
  { accessorKey: 'isFree', header: 'Is free' },
  {
    id: 'action',
    cell: ({ row }) => (
      <CellAction data={row.original} deleteAction={'course'} section={'course'} />
    ),
  },
];

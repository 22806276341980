import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type MenuStateType = {
  isCourseUpdateProcess: boolean;
  courseUpdateId: string | null;
};

const initialState: MenuStateType = {
  isCourseUpdateProcess: false,
  courseUpdateId: null,
};

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setCourseUpdateProcess: (state: MenuStateType) => {
      // eslint-disable-next-line no-param-reassign
      state.isCourseUpdateProcess = !state.isCourseUpdateProcess;
    },
    setCourseUpdateId: (state: MenuStateType, action: PayloadAction<string | null>) => {
      // eslint-disable-next-line no-param-reassign
      state.courseUpdateId = action.payload;
    },
  },
});

export const { setCourseUpdateProcess, setCourseUpdateId } = courseSlice.actions;
export default courseSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormCreator } from '../../components';
import {
  paymentProviderUpdateValidation,
  paymentProviderValidation,
} from './paymentProviderValidation';
import { usePaymentProviderCreatorFields } from './usePaymentProviderCreatorFields';
import { paymentProviderApi } from '../../api/services/paymentProviderApi';

const PaymentProviderNew: React.FC = () => {
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { paymentProvider },
        } = await paymentProviderApi.getPaymentProviderByUuid(uuid);

        setData(paymentProvider);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Payment Provider"
        description="Create a payment provider"
        validation={data ? paymentProviderUpdateValidation : paymentProviderValidation}
        fields={usePaymentProviderCreatorFields()}
        submitFunction={
          data ? paymentProviderApi.updatePaymentProvider : paymentProviderApi.createPaymentProvider
        }
        initialData={data}
      />
    )
  );
};

export default PaymentProviderNew;

export const usePaymentSystemCreatorFields = ({ paymentProviderOptions, paymentRegionOptions }) => [
  { name: 'isShown', label: 'Shown', type: 'checkbox', defaultValue: true },
  { name: 'image', label: 'Image', type: 'file' },
  { name: 'paymentSystemType', label: 'Name', type: 'text' },
  { name: 'paymentUrl', label: 'Payment url', type: 'text' },
  {
    name: 'providerUuid',
    label: 'Payment Provider',
    type: 'singleSelect',
    options: paymentProviderOptions,
  },
  {
    name: 'paymentRegionUuid',
    label: 'Payment Region',
    type: 'singleSelect',
    options: paymentRegionOptions,
  },
  { name: 'order', label: 'Order', type: 'number' },
  { name: 'currency', label: 'Currency', type: 'text' },
  { name: 'name', label: 'Name', type: 'text' },
];

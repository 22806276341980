import instance, { formDataInstance } from '../axios';

export const termsConditionsApi = {
  getTermsConditions: async () => instance.get('/v1/terms-conditions/get-admin'),
  getTermsConditionsByUuid: async (uuid) =>
    instance.get(`/v1/terms-conditions/get-by-uuid/${uuid}`),
  createTermsConditions: async (data) => instance.post('/v1/terms-conditions/create', data),
  updateTermsConditions: async (uuid, data) =>
    formDataInstance.put(`/v1/terms-conditions/update/${uuid}`, data),
  deleteTermsConditions: async (uuid) => instance.delete(`/v1/terms-conditions/delete/${uuid}`),
};

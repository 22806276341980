import { ColumnDef } from '@tanstack/react-table';

export type ColumnType = {
  id: string;
  startDate: string;
  endDate: string;
  reasonRejection: string;
  isActive: boolean;
  userUuid: string;
  priceUuid: string;
  price: string;
};

export const usePurchaseTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'startDate', header: 'Start date' },
  { accessorKey: 'endDate', header: 'End date' },
  { accessorKey: 'remainder', header: 'Days left' },
  { accessorKey: 'duration', header: 'Duration' },
  { accessorKey: 'reasonRejection', header: 'Reject reason' },
  { accessorKey: 'isActive', header: 'Is active' },
  { accessorKey: 'user', header: 'User' },
  { accessorKey: 'price', header: 'Price' },
  { accessorKey: 'orderId', header: 'Order Id' },
  { accessorKey: 'isStatusChecked', header: 'Status checked' },
];

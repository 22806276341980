import instance from '../axios';

export const priceApi = {
  getAllPrices: async (pageSize, currentPage, searchValue) =>
    instance.get('/v1/price/get-all-admin', { params: { pageSize, currentPage, searchValue } }),
  getPriceByUuid: async (uuid) => instance.get(`/v1/price/${uuid}`),
  createPrice: async (data) => instance.post('/v1/price/create', data),
  updatePrice: async (uuid, data) => instance.put(`/v1/price/update/${uuid}`, data),
  deletePrice: async (uuid) => instance.delete(`/v1/price/delete/${uuid}`),
};

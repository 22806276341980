import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { pageContentEn } from '../../../constants/pageContent';
import shownErrorMessage from '../../../helpers/shownErrorMessage';
import { privacyPolicyApi } from '../../../api/services/privacyPolicyApi';

export const useDeletePrivacyPolicy = () => {
  const queryClient = useQueryClient();

  // eslint-disable-next-line consistent-return
  const mutationFunction = async (deletedPrivacyPolicyUuid: string) => {
    try {
      const response = await privacyPolicyApi.deletePrivacyPolicy(deletedPrivacyPolicyUuid);
      toast.success(response?.data?.message || pageContentEn.PRIVACY_POLICY_DELETED_SUCCESSFULLY);
      return response;
    } catch (error) {
      const errorMessage = shownErrorMessage(error);
      toast.error(errorMessage);
    }
  };

  const { mutateAsync: deletePrivacyPolicyMutate } = useMutation({
    mutationFn: mutationFunction,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['privacyPolicy'] });
    },
  });

  return { deletePrivacyPolicyMutate };
};

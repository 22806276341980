import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PlusIcon } from 'lucide-react';
import { Separator } from '../@/components/ui/separator';
import { Button } from '../@/components/ui/button';
import { Heading } from '../components/Heading/Heading';
import { DataTable } from '../components/DataTable/DataTable';
import VisitDelete from '../components/VisitDelete/VisitDelete';

const Page = ({
  title,
  description,
  columns,
  formattedData,
  searchKey = 'nameEn',
  currentPage,
  setCurrentPage,
  dataTotalCount,
  pageSize,
  setSearchValue,
  isShown,
  isShownHandler,
  isShownOptions,
  isFree,
  isFreeHandler,
  isFreeOptions,
  languageOptions,
  languageHandler,
  language,
  isReady,
  isReadyHandler,
  isReadyOptions,
  subcategoryOptions,
  subcategoryHandler,
  subcategory,
  sourceOptions,
  sourceHandler,
  source,
  allowAdd = true,
  deleteAction = false,
  searchValueFromUrl = '',
}: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="flex flex-wrap justify-center mt-20 md:mt-1">
      <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl w-full md:w780">
        <div className="flex justify-between">
          <Heading title={title} description={description} />
          {allowAdd && (
            <Button onClick={() => navigate(`new`)}>
              <PlusIcon className="mr-2 h-4 w-4" />
              Add new
            </Button>
          )}
          {deleteAction && (
            <Button variant="destructive" onClick={() => setIsOpen(true)}>
              Delete
            </Button>
          )}
        </div>
        {location.pathname.includes('/visits') && (
          <VisitDelete isOpen={isOpen} setIsOpen={setIsOpen} />
        )}
      </div>
      <Separator />
      <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl w-full md:w780">
        <div>
          <DataTable
            data={formattedData}
            columns={columns}
            searchKey={searchKey}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            dataTotalCount={dataTotalCount}
            pageSize={pageSize}
            setSearchValue={setSearchValue}
            isShown={isShown}
            isFree={isFree}
            isFreeHandler={isFreeHandler}
            isShownHandler={isShownHandler}
            languageHandler={languageHandler}
            language={language}
            isFreeOptions={isFreeOptions}
            isShownOptions={isShownOptions}
            languageOptions={languageOptions}
            isReady={isReady}
            isReadyHandler={isReadyHandler}
            isReadyOption={isReadyOptions}
            subcategoryOptions={subcategoryOptions}
            subcategoryHandler={subcategoryHandler}
            subcategory={subcategory}
            sourceOptions={sourceOptions}
            sourceHandler={sourceHandler}
            source={source}
            searchValueFromUrl={searchValueFromUrl}
          />
        </div>
      </div>
    </div>
  );
};

export default Page;

import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  id: string;
  nameEn: string;
  nameRu: string;
};

export const useUserLanguageTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'image', header: 'Image' },
  { accessorKey: 'order', header: 'Order' },
  { accessorKey: 'name', header: 'Name' },
  { accessorKey: 'abbreviation', header: 'Abbreviation' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'userLanguage'} />,
  },
];

import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormCreator } from '../../components';
import { courseStorageApi } from '../../api/services/courseStorageApi';
import { courseStorageValidation } from './courseStorageValidation';
import { useCourseStorageCreatorFields } from './useCourseStorageCreatorFields';

const CourseStorageNew: React.FC = () => {
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { courseStorage },
        } = await courseStorageApi.getCourseStorageByUuid(uuid);

        setData(courseStorage);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Course Storage"
        description="Create a course storage"
        validation={courseStorageValidation}
        fields={useCourseStorageCreatorFields()}
        submitFunction={
          data ? courseStorageApi.updateCourseStorage : courseStorageApi.createCourseStorage
        }
        initialData={data}
      />
    )
  );
};

export default CourseStorageNew;

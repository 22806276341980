import instance, { formDataInstance } from '../axios';

export const subcategoriesApi = {
  getAllSubcategories: async (pageSize, currentPage, searchValue) =>
    instance.get('/v1/sub-category/get-all-admin', {
      params: { pageSize, currentPage, searchValue },
    }),
  getSubcategoryByUuid: async (uuid) => instance.get(`/v1/sub-category/${uuid}`),
  createSubcategory: async (data) => formDataInstance.post('/v1/sub-category/create', data),
  updateSubcategory: async (uuid, data) =>
    formDataInstance.put(`/v1/sub-category/update/${uuid}`, data),
  deleteSubcategory: async (uuid) => instance.delete(`/v1/sub-category/delete/${uuid}`),
};

'use client';

import React from 'react';
import Select, { ActionMeta, SingleValue } from 'react-select';

interface defaultValueType {
  value: string;
  label: string;
}

interface PropsType {
  defaultValue: defaultValueType | undefined;
  onChangeHandler: (
    newValue: SingleValue<defaultValueType>,
    actionMeta: ActionMeta<defaultValueType>
  ) => void;
  options: defaultValueType[];
  description: string;
}

const SBSelectFilter: React.FC<PropsType> = ({
  defaultValue,
  onChangeHandler,
  options,
  description,
}) => {
  const isDarkMode = true;

  return (
    <>
      <span className="text-sm text-muted-foreground">{description}</span>
      <Select
        defaultValue={defaultValue}
        onChange={onChangeHandler}
        options={options}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            height: '32px',
            width: '140px',
            minHeight: '39px',
            cursor: 'pointer',
            zIndex: '1000',
            backgroundColor: isDarkMode ? 'white' : 'white',
            borderColor: '#17ed90',
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: '0 8px',
            zIndex: '1000',
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: isDarkMode ? 'black' : 'black',
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            width: '230px',
            zIndex: '1001',
            backgroundColor: isDarkMode ? '#383838' : 'white',
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            color: isDarkMode ? 'white' : 'black',
            zIndex: '1000',
            // eslint-disable-next-line no-nested-ternary
            backgroundColor: state.isSelected
              ? '#30ffa4'
              : // eslint-disable-next-line no-nested-ternary
              isDarkMode
              ? state.isSelected
                ? '#17ed90'
                : '#383838'
              : state.isSelected
              ? '#17ed90'
              : 'white',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#565555',
            },
          }),
          indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            height: '32px',
          }),
        }}
      />
    </>
  );
};

export default SBSelectFilter;

import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormCreator } from '../../components';
import { orderApi } from '../../api/services/orderApi';
import { orderValidation } from './orderValidation';
import { useOrderCreatorFields } from './useOrderCreatorFields';

const OrderNew: React.FC = () => {
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { order },
        } = await orderApi.getOrderByUuid(uuid);

        setData(order);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Order"
        description={data ? 'Update a order' : 'Create a order'}
        validation={orderValidation}
        fields={useOrderCreatorFields()}
        submitFunction={data ? orderApi.updateOrder : orderApi.createOrder}
        initialData={data}
      />
    )
  );
};

export default OrderNew;

import * as yup from 'yup';

export const termsConditionsValidation = {
  termsConditionsEn: yup.string().required('Required'),
  termsConditionsRu: yup.string().required('Required'),
};

export const termsConditionsUpdateValidation = {
  termsConditionsEn: yup.string().required('Required'),
  termsConditionsRu: yup.string().required('Required'),
};

import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  nameEn: string;
  nameRu: string;
  abbreviation: string;
  order: number;
  isShown: boolean;
};

export const usePaymentRegionTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'order', header: 'Order' },
  { accessorKey: 'nameEn', header: 'Name english' },
  { accessorKey: 'nameRu', header: 'Name russian' },
  { accessorKey: 'abbreviation', header: 'Abbreviation' },
  { accessorKey: 'isShown', header: 'isShown' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'paymentRegion'} />,
  },
];

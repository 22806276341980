import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { FormCreator } from '../../components';
import { paymentRegionUpdateValidation, paymentRegionValidation } from './paymentRegionValidation';
import { usePaymentRegionCreatorFields } from './usePaymentRegionCreatorFields';
import { paymentRegionApi } from '../../api/services/paymentRegionApi';

interface optionType {
  value: string;
  label: string;
}

const PaymentRegionNew: React.FC = () => {
  const queryClient = useQueryClient();
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { paymentRegion },
        } = await paymentRegionApi.getPaymentRegionByUuid(uuid);

        setData(paymentRegion);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Payment Region"
        description="Create a payment region"
        validation={data ? paymentRegionUpdateValidation : paymentRegionValidation}
        fields={usePaymentRegionCreatorFields()}
        submitFunction={
          data ? paymentRegionApi.updatePaymentRegion : paymentRegionApi.createPaymentRegion
        }
        initialData={data}
      />
    )
  );
};

export default PaymentRegionNew;
